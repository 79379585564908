import React from 'react'

import {
  ChatBubbleOvalLeftEllipsisIcon,
  DocumentChartBarIcon,
  PhoneIcon,
  DocumentArrowDownIcon,
  MicrophoneIcon,
  SpeakerWaveIcon,
  ArrowTopRightOnSquareIcon,
  CalculatorIcon,
  ChartBarIcon,
  ChartPieIcon,
  ClipboardDocumentIcon,
  FolderOpenIcon,
  BuildingLibraryIcon,
  LinkIcon,
  MusicalNoteIcon
} from '@heroicons/react/24/outline'

// Expected prop values
export interface IconProps {
  documentIcon?: string;
  className?: string;
}

export const Icon: React.FunctionComponent<IconProps> = function Icon({
  documentIcon,
  className
}) {

  let iconMarkup

  switch (documentIcon) {
    case 'Chat':
      iconMarkup = (
        <ChatBubbleOvalLeftEllipsisIcon className={className} aria-hidden="true" />
      )
      break
    case 'Document':
      iconMarkup = (
        <DocumentChartBarIcon className={className} aria-hidden="true" />
      )
      break
    case 'Phone':
      iconMarkup = (
        <PhoneIcon className={className} aria-hidden="true" />
      )
      break
    case 'Folder':
      iconMarkup = (
        <FolderOpenIcon className={className} aria-hidden="true" />
      )
      break
    case 'Link':
      iconMarkup = (
        <LinkIcon className={className} aria-hidden="true" />
      )
      break
    case 'Library':
      iconMarkup = (
        <BuildingLibraryIcon className={className} aria-hidden="true" />
      )
      break
    case 'Music':
      iconMarkup = (
        <MusicalNoteIcon className={className} aria-hidden="true" />
      )
      break
    case 'Download':
      iconMarkup = (
        <DocumentArrowDownIcon className={className} aria-hidden="true" />
      )
      break
    case 'Microphone':
      iconMarkup = (
        <MicrophoneIcon className={className} aria-hidden="true" />
      )
      break
    case 'Speaker':
      iconMarkup = (
        <SpeakerWaveIcon className={className} aria-hidden="true" />
      )
      break
    case 'External':
      iconMarkup = (
        <ArrowTopRightOnSquareIcon className={className} aria-hidden="true" />
      )
      break
    case 'Calculator':
      iconMarkup = (
        <CalculatorIcon className={className} aria-hidden="true" />
      )
      break
    case 'Bar chart':
      iconMarkup = (
        <ChartBarIcon className={className} aria-hidden="true" />
      )
      break
    case 'Pie chart':
      iconMarkup = (
        <ChartPieIcon className={className} aria-hidden="true" />
      )
      break
    case 'Clipboard':
      iconMarkup = (
        <ClipboardDocumentIcon className={className} aria-hidden="true" />
      )
      break
    default:
      // nothing
  }

  return (
    <>
      {iconMarkup}
    </>
  )
}