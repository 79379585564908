import { useStaticQuery, graphql } from 'gatsby'

const reportsPageStaticQuery = graphql`
  query reportsPageQuery {
    contentfulCustomPage(slug: { eq: "reports" }) {
      id
      title
      noResults {
        noResults
      }
      hero {
        ...HeroFragment
      }
    }
  }
`

const useReportsPage = () => {
  const data = useStaticQuery(reportsPageStaticQuery)

  return data.contentfulCustomPage
}

export default useReportsPage
